import React, { useEffect, useRef } from "react";
import { Tooltip as BootstrapTooltip } from "bootstrap";

// Custom Tooltip component using Bootstrap's Tooltip
export const Tooltip = ({ children, text, placement = "top" }) => {
  const childRef = useRef(null);

  useEffect(() => {
    // Initialize Bootstrap tooltip
    const tooltipInstance = new BootstrapTooltip(childRef.current, {
      title: text,
      placement,
      trigger: "hover", // Default trigger is hover
    });

    // Cleanup function to dispose of the tooltip instance when the component unmounts
    return () => {
      if (tooltipInstance) {
        tooltipInstance.dispose();
      }
    };
  }, [text, placement]); // Dependencies array to re-create the tooltip when these props change

  // Cloning the child to attach the ref
  return React.cloneElement(children, { ref: childRef });
};

export default Tooltip;
