import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Project Management",
      desc: "During my tenure in the hospitality industry, I successfully managed a comprehensive digital transformation project at a luxury hotel. This involved integrating a new Property Management System (PMS) to streamline operations and enhance guest experiences. My role included coordinating with IT vendors, training staff on new software, and troubleshooting implementation issues. The project not only improved operational efficiency but also provided me with valuable insights into IT project management, system integration, and team leadership.",
      icon: "fas fa-project-diagram", // Updated to represent project management
    },
    {
      name: "Data Analysis and Optimization",
      desc: "In the hospitality sector, I utilized data analytics to optimize the hotel's revenue management strategies. By implementing a data-driven approach, I analyzed booking patterns, guest preferences, and seasonal trends. This led to a 15% increase in revenue through better pricing strategies and targeted marketing campaigns. My ability to interpret data and apply analytical skills to real-world problems has prepared me for similar challenges in the IT industry, where data analysis is crucial for decision-making and system optimization.",
      icon: "fas fa-chart-bar", // Updated to represent data analysis
    },
    {
      name: "Customer Relationship Management (CRM)",
      desc: "I spearheaded the implementation of a Customer Relationship Management (CRM) system at a major hotel chain. This involved customizing the CRM to meet the unique needs of the hospitality business, training staff, and ensuring seamless integration with existing systems. The CRM improved customer engagement, streamlined communication, and enhanced guest satisfaction. My experience with CRM systems has equipped me with the technical knowledge and customer-centric approach essential for roles in IT where customer relationship management is key.",
      icon: "fas fa-users", // Updated to represent CRM
    },
    {
      name: "Cybersecurity Awareness and Training",
      desc: "As part of my role in hospitality management, I led cybersecurity awareness training sessions for hotel staff. Recognizing the importance of data security in protecting guest information, I collaborated with IT experts to develop and deliver training modules on topics such as phishing, password security, and data privacy. This initiative significantly reduced the risk of data breaches and highlighted my commitment to cybersecurity—a critical area in the IT field.",
      icon: "fas fa-shield-alt", // Updated to represent cybersecurity
    },
    {
      name: "System Upgrade and IT Support",
      desc: "I managed a major system upgrade at a hotel, transitioning from legacy systems to a modern, cloud-based infrastructure. This project involved detailed planning, vendor selection, and coordination with IT support teams to ensure minimal disruption to operations. My hands-on experience with system upgrades and providing IT support during critical transitions has prepared me for similar challenges in the IT industry, where technical expertise and problem-solving skills are paramount.",
      icon: "fas fa-cloud-upload-alt", // Updated to represent system upgrades
    },
    {
      name: "Digital Marketing and SEO",
      desc: "In my hospitality role, I led a digital marketing campaign to boost the hotel's online presence. By optimizing the hotel's website for search engines (SEO), managing social media accounts, and creating engaging content, I increased online bookings by 20%. My proficiency in digital marketing tools and techniques, along with my understanding of SEO, is highly relevant to IT roles focused on web development and digital marketing strategies.",
      icon: "fas fa-search", // Updated to represent SEO
    },
    {
      name: "Agile Project Management",
      desc: "I introduced Agile project management methodologies to streamline the development and deployment of new hotel services. By adopting Scrum practices, I facilitated daily stand-ups, sprint planning, and retrospectives, which improved team collaboration and project delivery timelines. My experience with Agile methodologies is directly applicable to IT environments where Agile practices are commonly used to enhance productivity and project outcomes.",
      icon: "fas fa-tasks", // Updated to represent Agile project management
    },
    {
      name: "IT Networking and Infrastructure",
      desc: "While managing IT operations at a hospitality venue, I oversaw the installation and maintenance of a robust networking infrastructure. This included setting up secure Wi-Fi networks, configuring routers and switches, and ensuring reliable internet access for both staff and guests. My technical expertise in networking and infrastructure management is crucial for IT roles that require a strong foundation in IT operations and network administration.",
      icon: "fas fa-network-wired", // Updated to represent IT networking
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Embedded styles for hover effects */}
        <style>
          {`
            .hover-effect {
              transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
            }
            .hover-effect:hover {
              transform: scale(1.05);
              box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
            }
          `}
        </style>
        {/* Other content */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.map((service, index) => (
                <div className="col-md-6" key={index}>
                  <div className="featured-box style-3 mb-5 hover-effect">
                    <div
                      className={
                        "featured-box-icon text-primary shadow-sm rounded " +
                        (darkTheme ? "bg-dark-1" : "bg-white")
                      }
                    >
                      <i className={service.icon} />
                    </div>
                    <h3 className={darkTheme ? "text-white" : ""}>
                      {service.name}
                    </h3>
                    <p
                      className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      style={{ textAlign: "justify" }}
                    >
                      {service.desc}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
