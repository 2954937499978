import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
import "react-alice-carousel/lib/scss/alice-carousel.scss";

// Use these components in your React code

const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    FRONT_END: "Front_End",
    BACK_END: "Back_End",
    FULL_STACK: "Full_Stack",
    GAME_DESIGN: "Game_Design",
  };

  const projectsData = [
    {
      title: "Project-1 AbeGarage",
      projectInfo:
        "I developed the AbeGarage platform, enhancing car trading with a user-friendly interface, advanced search, robust security, and efficient backend management.",
      client: "Amare Biyabeyin",
      technologies:
        "AWS, Express, React, Node, Mysql, RESTapi, HTML5, CSS3, JavaScript",
      industry: "Web Development",
      date: "January 25, 2024 started and in progress",
      url: {
        name: "www.amarebiyabeyin.com",
        link: "",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/AbeGarage",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/abe_garage.png",
      sliderImages: [
        "images/projects/abe_garage.png",
        "images/projects/netflix-5.jpeg",
      ],
      categories: [filters.BACK_END],
    },
    {
      title: "Project-2 Amazon-Clone-Frontend",
      projectInfo: "Amazon-Clone front-end",
      client: "Amare Biayebeyin",
      technologies: "React, Express, Node",
      industry: "Software Developement",
      date: "July 16, 2023",
      url: {
        name: "https://clone-frontend-f0e56.web.app",
        link: "https://clone-frontend-f0e56.web.app",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/amazon-clone-frontend",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/amazon-2.jpeg",
      sliderImages: [
        "images/projects/amazon-2.jpeg",
        "images/projects/netflix-5.jpeg",
      ],
      categories: [filters.FRONT_END],
    },
    {
      title: "Project-3 Apple-Clone",
      projectInfo:
        "In this project, I replicated Apple's website, precisely mirroring their iconic UI, product pages, and navigation, demonstrating expertise in creating seamless digital interfaces. ",
      client: "Amare Biyabeyin",
      technologies:
        "HTML/CSS/JavaScript,(RWD), React.js, Backend Development, Node.js, Database Management, MySQL, APIs (RESTful APIs), HTTPS",
      industry: "Web Design",
      date: "July 1, 2023",
      url: {
        name: "www.amarebiyabeyin.com",
        link: "",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/Apple-website-with-react",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/apple-3.jpeg",
      sliderImages: [
        "images/projects/apple-3.jpeg",
        "images/projects/netflix-5.jpeg",
      ],
      categories: [filters.PHOTOS],
    },
    {
      title: "Project-4 Evangadi Forum",
      projectInfo:
        "Developed Evangadi Forum using Vite, React, and Bootstrap; a vibrant community for tech enthusiasts to connect, share, and solve coding issues.",
      client: "Amare Biyabeyin",
      technologies:
        "Express, React, Node, Mysql, RESTapi, HTML5, CSS3, JavaScript",
      industry: "Web Design",
      date: "October 30, 2023",
      url: {
        name: "www.amarebiyabeyin.com",
        link: "",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/evangadi-forum",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/evangadiforum-4.jpg",
      sliderImages: [
        "images/projects/evangadiforum-4.jpg",
        "images/projects/evangadiforum-4.jpg",
      ],
      categories: [filters.FRONT_END],
    },
    {
      title: "Project-5 Netflix-Clone",
      projectInfo:
        "Netflix Clone project showcases web development skills by recreating the immersive streaming experience of movies and TV shows on multiple devices.",
      client: "Amare Biyabeyin",
      technologies:
        "HTML/CSS/JavaScript React, Context API, Fetch API, React Router, Node.js Express.js, Database, RESTful API, Authentication (JWT)",
      industry: " Web Design",
      date: "September 30, 2023",
      url: {
        name: "www.amarebiyabeyin.com/",
        link: "",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/netflix-clone-new",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/netflix-5.jpeg",
      sliderImages: [
        "images/projects/abe_garage.png",
        "images/projects/netflix-5.jpeg",
      ],
      categories: [filters.FRONT_END],
    },
    {
      title: "Project-6 Zoom Clone",
      projectInfo:
        " Zoom Clone replicates the original's virtual communication features with high-quality video, chat, and strong security for effortless user collaboration.",
      client: "Amare Biyabeyin",
      technologies:
        "HTML CSS, JavaScript, React.js, Node.js, WebSocket, Database, Security Protocols (HTTPS, JWT)",
      date: "October 16, 2023",
      url: {
        name: "www.amarebiyabeyin.com",
        link: "",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/zoom-clone-nodejs",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/zoom-6.jpeg",
      sliderImages: [
        "images/projects/zoom-6.jpeg",
        "images/projects/zoom-6.jpeg",
      ],
      categories: [filters.FRONT_END],
    },
    {
      title: "Project-7 Apple-replica",
      projectInfo: "Apple Replica: apple project replicates.",
      client: "Amare Biyabeyin",
      technologies: "HTML CSS, BootStrap",
      date: "May 30, 2023",
      url: {
        name: "www.amarebiyabeyin.com",
        link: "",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/Apple-replica-html-css-bootstrap",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/appleBootstrap.jpg",
      sliderImages: [
        "images/projects/appleBootstrap.jpg",
        "images/projects/appleBootstrap.jpg",
      ],
      categories: [filters.FRONT_END],
    },
    {
      title: "Project-8 Snake-Game",
      projectInfo: "Snake-game.",
      client: "Amare Biyabeyin",
      technologies: "HTML5, CSS3, PHP, JavaScripts",
      industry: "Web Design",
      date: "April 16, 2024",
      url: {
        name: "www.amarebiyabeyin.com",
        link: "",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/Snake-Game",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/snake-game.jpeg",
      sliderImages: [
        "images/projects/snake-game.jpeg",
        "images/projects/snake-game.jpeg",
      ],
      categories: [filters.GAME_DESIGN],
    },
    {
      title: "Project-9 Text to Speech",
      projectInfo: "Text-to-Spech.",
      client: "Amare Biyabeyin",
      technologies: "HTML5, CSS3, JavaScripts",
      industry: "Web Application",
      date: "May 29, 2024",
      url: {
        name: "www.amarebiyabeyin.com",
        link: "",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/Text-to-Speech",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/2.png",
      sliderImages: [
        "images/projects/2.png",
        "images/projects/snake-game.jpeg",
      ],
      categories: [filters.GAME_DESIGN],
    },
    {
      title: "Project-10 Calculator",
      projectInfo: "Simple Calculator",
      client: "Amare Biyabeyin",
      technologies: "HTML5, CSS3, JavaScripts",
      industry: "Web Application",
      date: "May 29, 2024",
      url: {
        name: "www.amarebiyabeyin.com",
        link: "",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/calculator",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/3.png",
      sliderImages: ["images/projects/2.png", "images/projects/3.png"],
      categories: [filters.GAME_DESIGN],
    },
    {
      title: "Project-11 Amazon-Clone-front-end and back-end",
      projectInfo: "amazon-clone frontend-backend",
      client: "Amare Biayebeyin",
      technologies: "React, Express, Node.js",
      industry: "Software Developement",
      date: "October 15, 2023",
      url: {
        name: "https://clone-frontend-f0e56.web.app",
        link: "https://clone-frontend-f0e56.web.app",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/amazon-clone-frontend-backend",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/amazon.jpg",
      sliderImages: [
        "images/projects/amazon.jpg",
        "images/projects/amazon.jpg",
      ],
      categories: [filters.FULL_STACK],
    },
    {
      title: "Project-12 Hotel Reservation System",
      projectInfo: "hotel-reservation-system",
      client: "Amare Biayebeyin",
      technologies: "React, Express, Node.js and Mysql",
      industry: "Full Stack Developement",
      date: "June 10, 2024",
      url: {
        name: "https://amarekassa.com",
        link: "",
      },
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/amare-b-564582183/",

        github: "https://github.com/amareb/hotel-reservation-system",
        mail: "mailto:amare.kassab@gmail.com",
      },
      thumbImage: "images/projects/ama.PNG",
      sliderImages: ["images/projects/ama.PNG", "images/projects/ama.PNG"],
      categories: [filters.FULL_STACK],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              Projects
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
