import React from "react";
import styled, { keyframes } from "styled-components";

// Define keyframes for animations
const ellipsis1 = keyframes`
  0% { transform: scale(0); }
  100% { transform: scale(1); }
`;

const ellipsis2 = keyframes`
  0% { transform: translate(0, 0); }
  100% { transform: translate(24px, 0); }
`;

const ellipsis3 = keyframes`
  0% { transform: scale(1); }
  100% { transform: scale(0); }
`;

// Styled component for the preloader wrapper
const PreloaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
`;

// Styled component for the ellipsis (dots container)
const Ellipsis = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;

// Styled component for each dot
const Dot = styled.div`
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #333;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 8px;
    animation: ${ellipsis1} 0.6s infinite;
  }
  &:nth-child(2) {
    left: 8px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &:nth-child(3) {
    left: 32px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &:nth-child(4) {
    left: 56px;
    animation: ${ellipsis3} 0.6s infinite;
  }
`;

// PreLoader component
const PreLoader = () => {
  return (
    <PreloaderDiv>
      <Ellipsis>
        <Dot />
        <Dot />
        <Dot />
        <Dot />
      </Ellipsis>
    </PreloaderDiv>
  );
};

export default PreLoader;