import React from "react";
import Slider from "react-slick";
import recommendationLetter1 from "../documents/recommendation-letter-rosalind.pdf"; // Path to Professor Rosalind's recommendation letter PDF

const Testimonials = ({ classicHeader, darkTheme }) => {
  const reviews = [
    {
      name: "Professor Rosalind Archer, Acting Pro Vice Chancellor, Griffith University",
      position: "Australia",
      src: "images/testimonial/client-sm-3.jpg",
      desc: "Amare Biyabeyin is a dedicated and enthusiastic student who achieved Bronze+, Silver+, and Gold+ “STEM Professionals ICT” awards within a single trimester. He excels in professional development, demonstrated through his high-quality portfolio, social media campaign management, revenue optimization, cybersecurity training, and agile project management. Amare's diverse background includes hospitality industry experience and strong business knowledge. His portfolio showcases responsive web design and clean coding. His commitment to learning and impressive achievements make him a standout student, I have no doubt he will continue to excel in future endeavors.",
      rating: 5,
      letter: recommendationLetter1,
    },
    {
      name: "Ms. Gayle Brent, Learning and Teaching Consultant (Employability), Griffith University",
      position: "Australia",
      src: "images/testimonial/client-sm-4.jpg",
      desc: "Amare Biyabeyin has proven himself to be a highly motivated and engaged learner, with a keen focus on personal and professional development. His active involvement in the STEM Professionals program and the achievement of Gold+ are testament to his commitment and drive for excellence. Throughout our interactions, Amare has demonstrated his communication abilities, evidencing his proactive approach to fostering professional connections. It has been a pleasure to work with Amare, and I have no doubt that he will continue to impress as he progresses through his career.",
      rating: 5,
      linkedIn: "https://www.linkedin.com/in/amare-b-564582183/", // Replace with the actual LinkedIn recommendation URL
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24 text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Recommendations
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Endorsements
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    "rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {value.position}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      "fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                    style={{ textAlign: "justify" }}
                  >
                    “{value.desc}”
                  </p>
                  <div className="button-container">
                    {value.letter && (
                      <a
                        href={value.letter}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-oval"
                      >
                        Read Full Recommendation
                      </a>
                    )}
                    {value.linkedIn && (
                      <a
                        href={value.linkedIn}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-oval"
                      >
                        View LinkedIn Recommendation
                      </a>
                    )}
                  </div>
                  <span className="text-2">
                    {[...Array(value.rating)].map((_, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
