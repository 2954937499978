import React from "react";
import resumeFile from "../documents/resume.pdf";

const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24 text-uppercase fw-600 w-500 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            <div>Discover More About Me</div>
            <span className="heading-separator-line border-bottom border-1 border-primary d-block mx-auto" />
          </p>
          <div>
            {/* Rounded image placed at the top-right corner */}
            <div style={{ position: "absolute", right: "0", top: "0" }}>
              <img
                src="/images/profile.jpg"
                alt="profile"
                style={{
                  width: "245px",
                  height: "245px",
                  borderRadius: "100%", // CSS for making the image rounded
                }}
              />
            </div>
          </div>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Amare Biyabeyin,</span> a Full
              Stack Web Developer
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              I specialize in building and revitalizing brands for businesses,
              and I am dedicated to delivering a strong brand identity and
              exceptional value to my clients.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              <div className="col-lg-7 col-xl-8 text-center text-lg-start"></div>
              <h2
                className={
                  "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
                }
              >
                <span className="text-primary">Achivements</span>
              </h2>
            </p>
            <p style={{ textAlign: "justify" }}>
              Griffith University STEM Professionals+ Achievements: As part of a
              university project, I managed a successful social media campaign
              for "Community Cares," which increased event attendance and
              donations, demonstrating flexibility and teamwork. I highlighted
              personal branding with "Precision, Collaboration, Innovation"
              through revamping a hotel booking system. For connecting with IT
              professionals, I identified key connections, updated my LinkedIn,
              joined industry groups, and conducted informational interviews,
              leading to practical advice and an internship. I improved
              communication and resolved technical issues during a digital
              check-in rollout, turning setbacks into learning opportunities.
              <p></p>
              Additionally, I utilized data analytics to optimize revenue
              management strategies, leading to a 15% increase in revenue, and
              spearheaded the implementation of a CRM system, enhancing customer
              engagement and satisfaction. I led cybersecurity awareness
              training sessions to protect guest information, managed a major
              system upgrade transitioning to cloud-based infrastructure, and
              drove a digital marketing campaign that boosted online bookings by
              20%. My experience with Agile project management and overseeing IT
              networking and infrastructure further solidifies my readiness for
              roles in IT, highlighting my adaptability, technical knowledge,
              and problem-solving skills.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal details start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Amare Biyabeyin
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:amare.kassab@gmail.com">Contact Me</a>
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Brisbane, Australia
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download Resume
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>2</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experience
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>12</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>2</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Testimonials
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;