import React, { useState } from "react";
import resumeFile from "../documents/resume.pdf";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHtml5,
  faCss3Alt,
  faJsSquare,
  faReact,
  faNodeJs,
  faBootstrap,
  faGitAlt,
  faPhp,
} from "@fortawesome/free-brands-svg-icons";
import { faDatabase, faServer } from "@fortawesome/free-solid-svg-icons";
import { faPython } from "@fortawesome/free-brands-svg-icons";

const Resume = ({ classicHeader, darkTheme }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const educationDetails = [
    {
      yearRange: "2023 - 2024",
      title:
        "Full Stack Engineer from Codecademy and Full Stack Web Development (MERN) from Evangadi Academy",
      place: "USA",
      desc: "Proficient in both front-end and back-end development, adept at building and maintaining entire web applications. Uses client-side frameworks like React and server-side technologies such as Node.js, Express, along with managing databases like MySQL, PostgreSQL and deploying applications and managing APIs.",
    },
    {
      yearRange: "2018 - 2024",
      title: "Bachelor of Information Technology and Bachelor of Business",
      place: "Griffith University",
      desc: "Dual degree program offers a comprehensive education that combines technical expertise with business acumen and a solid foundation in IT.",
    },
    {
      yearRange: "2004 - 2010",
      title:
        "Bachelor Degree in Management from ILI and Professional Diploma in Management from AAU",
      place:
        "International Leadership Institute and Addis Ababa University, Ethiopia",
      desc: "Studying management involves a solid choice for building a foundation in business principles and developing the leadership skills necessary for various managerial roles across industries and equips you with essential business and management skills.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2023 - 2024",
      title: "Junior Full Stack Developer",
      place: "",
      desc: "Extensive experience in developing web applications with a strong emphasis on efficiency. Skills include proficiency in HTML, CSS, SASS, Bootstrap, JavaScript, React, Node.js, Express.js, MySQL, and REST API integration. Adept at leveraging these technologies to create robust and user-friendly web solutions.",
    },
    {
      yearRange: "2023 - 2024",
      title: "Built a Fully Responsive Website",
      place: "",
      desc: "Creating a fully responsive website involves designing and coding a site that automatically adjusts its layout, images, and content to fit different screen sizes and orientations. This ensures a smooth user experience across all devices, from desktops to smartphones. Here's a basic guide to help you build a fully responsive website using HTML, CSS, and optionally, JavaScript and frameworks like Bootstrap",
    },
    {
      yearRange: "2023 - 2024",
      title: "Writing Clean Code",
      place: "",
      desc: "Writing clean code is essential for readability and maintainability. Practices include using descriptive names, consistent formatting, and following the Single Responsibility Principle (SRP) to ensure each entity has a single purpose. Avoids duplicating code and writes clear comments to explain complex logic. Implements proper error handling and writes automated tests for validation. Regularly refactors code to improve its structure and simplicity. Seeks feedback through code reviews and utilizes version control.",
    },
  ];

  const skills = [
    { name: "React", icon: faReact, color: "#61DAFB" },
    { name: "Node.js", icon: faNodeJs, color: "#68A063" },
    { name: "JavaScript", icon: faJsSquare, color: "#F0DB4F" },
    { name: "Bootstrap", icon: faBootstrap, color: "#7952B3" },
    { name: "Git", icon: faGitAlt, color: "#F05032" },
    { name: "PHP", icon: faPhp, color: "#8892BF" },
    { name: "MySQL", icon: faDatabase, color: "#4479A1" },
    { name: "PostgreSQL", icon: faDatabase, color: "#336791" },
    { name: "API", icon: faServer, color: "#6CBEEF" },
    { name: "MongoDB", icon: faDatabase, color: "#439A4D" },
    { name: "Express.js", icon: faNodeJs, color: "#68A063" },
    { name: "HTML5", icon: faHtml5, color: "#E34F26" },
    { name: "CSS3", icon: faCss3Alt, color: "#2965F1" },
    { name: "Python", icon: faPython, color: "#4B8BBE" },
  ];

  const hoverEffectStyle = {
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  };

  const hoverEffect = {
    transform: "scale(1.05)",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
  };

  return (
    <section id="resume" className={`section ${darkTheme ? "bg-dark-1" : ""}`}>
      <div className={`container ${classicHeader ? "" : "px-lg-5"}`}>
        <div className="row gx-5">
          {/* Education Section */}
          <div className="col-md-6">
            <h2
              className={`text-6 fw-600 mb-4 ${darkTheme ? "text-white" : ""}`}
            >
              Education
            </h2>
            {educationDetails.map((edu, index) => (
              <div
                key={index}
                style={{
                  ...hoverEffectStyle,
                  ...(hoveredIndex === index ? hoverEffect : null),
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                className={`bg-white rounded p-4 mb-4 ${
                  darkTheme ? "bg-dark" : "bg-white border"
                }`}
              >
                <p className="badge bg-primary text-2 fw-400">
                  {edu.yearRange}
                </p>
                <h3 className={`text-5 ${darkTheme ? "text-white" : ""}`}>
                  {edu.title}
                </h3>
                <p className={`text-4 mb-3 ${darkTheme ? "text-white" : ""}`}>
                  {edu.place}
                </p>
                <p
                  className={`mb-2 ${darkTheme ? "text-white-50" : ""}`}
                  style={{ textAlign: "justify" }}
                >
                  {edu.desc}
                </p>
              </div>
            ))}
          </div>
          {/* Experience Section */}
          <div className="col-md-6">
            <h2
              className={`text-6 fw-600 mb-4 ${darkTheme ? "text-white" : ""}`}
            >
              Experience
            </h2>
            {experienceDetails.map((exp, index) => (
              <div
                key={index}
                style={{
                  ...hoverEffectStyle,
                  ...(hoveredIndex === index + educationDetails.length
                    ? hoverEffect
                    : null),
                }}
                onMouseEnter={() =>
                  handleMouseEnter(index + educationDetails.length)
                }
                onMouseLeave={handleMouseLeave}
                className={`bg-white rounded p-4 mb-4 ${
                  darkTheme ? "bg-dark" : "bg-white border"
                }`}
              >
                <p className="badge bg-success text-2 fw-400">
                  {exp.yearRange}
                </p>
                <h3 className={`text-5 ${darkTheme ? "text-white" : ""}`}>
                  {exp.title}
                </h3>
                <p
                  className={`mb-2 ${darkTheme ? "text-white-50" : ""}`}
                  style={{ textAlign: "justify" }}
                >
                  {exp.desc}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* Skills Section */}
        <div className="row mt-5">
          <div className="col-12">
            <h2 className="text-6 fw-600 mb-4">Skills</h2>
            <div className="d-flex flex-wrap justify-content-center">
              {skills.map((skill, index) => (
                <div
                  key={index}
                  className="m-2"
                  style={{
                    ...hoverEffectStyle,
                    ...(hoveredIndex === index ? hoverEffect : {}),
                  }}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <FontAwesomeIcon
                    icon={skill.icon}
                    size="3x"
                    style={{ color: skill.color }}
                  />
                  <p className="mt-2">{skill.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
